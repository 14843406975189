<template>
  <div class="myFarm examiner">
    <section class="farmInfo">
      <h3>
        {{ username }} 심사원님,<br />
        안녕하세요!
      </h3>
      <div class="searchWrap">
        <input type="text" placeholder="농장주 검색" v-model="searchWord" @keyup.enter="search" />
        <button @click="search">
          <span class="material-icons-round"> search </span>
        </button>
      </div>
    </section>
    <section class="farmsList" v-if="farmerList.length > 0">
      <ul>
        <router-link to @click.native="handleMove(data)" tag="li" class="box" v-for="(data, i) in farmerList.filter((item) => item.withdrawAt == null)" :key="i">
          <p><span>농장주</span>{{ data.username }}</p>
          <p><span>주소</span>{{ data.userFarm.length > 0 ? data.userFarm[0].address : "등록된 농장 주소가 없습니다." }}</p>
        </router-link>
      </ul>
    </section>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { fetchFarmerList } from "@/api/index";

export default {
  data() {
    return { searchWord: "", farmers: [] };
  },
  computed: {
    ...mapState(["username", "farmerList"]),
  },
  created() {
    this.$store.dispatch("SET_NAVBAR", 0);
    this.$store.dispatch("SET_MENU_VISIBLE", true);
    this.$store.dispatch("SET_NAVBAR_VISIBLE", false);

    this.$store.dispatch("getDeviceInfo", null);
  },
  mounted() {
    if (this.farmerList) {
      this.farmers = this.farmerList;
    } else {
      this.getFarmerList();
    }
  },
  beforeDestroy() {
    this.searchWord = "";
    this.getFarmerList();
  },
  methods: {
    search() {
      this.farmers = [];
      this.getFarmerList();
    },
    getFarmerList() {
      let params = {
        searchWord: this.searchWord,
      };
      fetchFarmerList(params).then((res) => {
        if (res.data.status == 200) {
          let data = res.data.data;
          this.$store.commit("SET_FARMERLIST", data);
        } else {
          let message = res.data.message;
          alert(message);
        }
      });
    },
    handleMove(data) {
      if (data.userFarm.length > 0) {
        this.$store.commit("SET_USER_MAINFARM", data.userFarm[0]);
      } else {
        this.$store.commit("SET_USER_MAINFARM", null);
      }
      this.$router.push({ name: "home", query: { id: data._id } });
    },
  },
};
</script>
